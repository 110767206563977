/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { SyntheticEvent, useState } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const { token } = useParams();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const data = {
      token,
      password,
      password_confirm: passwordConfirm,
    };

    // Call the API endpoint with the reset data
    axios
      .post(`api/reset`, data)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: res.data.message, // Message from the response
            icon: "success", // Success icon
            confirmButtonText: "OK", // Confirm button text
            customClass: {
              confirmButton: "btn btn-success", // Green button styling
            },
          });
          setRedirect(true); // Redirect on success
        } else {
          Swal.fire({
            title: "Warning!",
            text: "Password reset failed. Please try again.", // Failure message
            icon: "error", // Error icon
            confirmButtonText: "OK", // Confirm button text
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          navigate(-1); // Navigate back
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text:
            error.response?.data?.message ||
            "An error occurred while processing your request.",
          icon: "error", // Error icon
          confirmButtonText: "OK", // Confirm button text
          customClass: {
            confirmButton: "btn btn-danger", // Red button styling for errors
          },
        });
        navigate(-1); // Navigate back
      });
  };

  // Redirect if the reset was successful
  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div
        className="container py-5"
        style={{ position: "absolute", top: "10%", left: "30%" }}
      >
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h4>Reset Password</h4>
              </div>
              <div className="card-body">
                <form onSubmit={submit}>
                  <h1 className="h3 mb-3 fw-normal">Reset your password</h1>

                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPasswordConfirm"
                      placeholder="Password Confirm"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordConfirm">
                      Password Confirm
                    </label>
                  </div>

                  <button
                    className="w-100 btn btn-lg btn-primary mt-3"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </div>
  );
}
